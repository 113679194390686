//
//
//
import { isAuthenticated } from "@/core/services/jwt.service";
export default {
  created: function created() {
    var _this = this;

    isAuthenticated().then(function (param) {
      if (param) {
        if (_this.isEngineerChannel()) {
          _this.$router.push({
            name: "engineer.dashboard"
          });
        } else {
          _this.$router.push({
            name: "admin.dashboard"
          });
        }
      } else {
        _this.$router.push({
          name: "admin.dashboard"
        });
      }
    }).catch(function () {
      _this.$router.push({
        name: "admin.login"
      });
    });
  }
};